<template>
  <div id="select">
    <div class="list-box">
      <div class="choose">
        <div class="item">
          <template>
            来源：
            <el-select
              v-model="formValue"
              placeholder="请选择"
              :size="$store.state.isMobile ? 'mini' : 'small'"
              @change="changeFormValue"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
        </div>

        <div class="item">
          <template>
            类目：
            <el-select
              v-model="chooseValue"
              clearable
              placeholder="请选择"
              @change="chooseType"
              :size="$store.state.isMobile ? 'mini' : 'small'"
            >
              <el-option
                v-for="item in chooseList"
                :key="item.value"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </div>

        <div class="item">
          <div class="keyword">
            <el-input
              class="inputwidth"
              :size="$store.state.isMobile ? 'mini' : 'small'"
              v-model="keyword"
              clearable
              @clear="clearSearch"
              placeholder="请输入内容"
            ></el-input>
            <el-button
              style="margin-left:10px"
              @click="search"
              type="primary"
              :size="$store.state.isMobile ? 'mini' : 'small'"
              icon="el-icon-search"
              >搜索</el-button
            >
          </div>
        </div>
      </div>
<!-- v-infinite-scroll="load" -->
      <div class="Selection" >
        <el-row
          class="flex"
          :gutter="$store.state.isMobile ? 10 : 20"
          v-loading="loading"
        >
          <el-col
            :span="$store.state.isMobile ? 12 : 6"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="grid-content bg-purple item">
              <img v-lazy="item.cover" ref="pic" :alt="item.title" />
              <div class="price">
                进货价:<span>{{ item.sale_price / 100 }}元</span>
                <!-- is_free_shipping -->
                <el-tag
                  :type="item.is_free_shipping == 1 ? 'danger' : 'warning'"
                  size="mini"
                  >{{ item.is_free_shipping == 1 ? "包邮" : "不包邮" }}</el-tag
                >
              </div>
              <div class="price price2">
                指导价:<span>{{ item.guide_price / 100 }}元</span>
              </div>

              <h6>{{ item.title }}</h6>

              <div class="btn">

                <!-- ifcaiji -->
                <el-button
                  v-if='item.ifcaiji==0'
                  type="danger"
                  @click="add(item.id)"
                  :icon="!$store.state.isMobile?'el-icon-sell':''"
                  :size="$store.state.isMobile ? 'mini' : 'small'"
                  >铺货</el-button
                >

                 <el-button
                 v-else
                  type="danger"
                  disabled
                 
                  :icon="!$store.state.isMobile?'el-icon-sell':''"
                  :size="$store.state.isMobile ? 'mini' : 'small'"
                  >已采集</el-button
                >


                <el-button
                  @click="open(item.id,item.third_id)"
                  :icon="!$store.state.isMobile?'el-icon-box':''"
                  type="danger"
                  plain
                  :size="$store.state.isMobile ? 'mini' : 'small'"
                  >详情</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
          <div class="back">
         <el-button type="primary" @click="load" round>加载更多</el-button>
      </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" >
@media screen and (max-width: 750px) {
  .el-select .el-input__inner {
    padding: 0 10px !important;
    width: 80px;
  }
  #select {
    height: 96%;
  }
  .inputwidth {
    width: 50%;
  }
  .keyword {
    padding-left: 0 !important;
  }
  #select .list-box {
    padding: 0 10px;
  }
  #select .list-box .choose {
    flex-wrap: wrap;
    height: 100px;

    .item {
      margin-left: 0 !important;
    }

    .item:nth-child(1),
    .item:nth-child(2) {
      width: 50%;
    }
    .item:nth-child(3) {
      width: 100%;
    }
  }
  #select .Selection .item .price {
    flex-wrap: wrap;
  }
}

#select .list-box .choose {
  justify-content: flex-start;

  .item {
    flex: inherit !important;
    margin-left: 10px;
  }
}
.inputwidth {
  width: 80%;
}
.back {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.choose .item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.choose .item .keyword {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0 14px;
}
#select .Selection .item .price2 {
  margin-top: 6px;
  line-height: 14px;
  color: #7d7d7d;
}
</style>
<script>
export default {
  name: "Shopping",

  data() {
    return {
      list: [],
      options: [
        {
          value: 0,
          label: "云仓"
        },
        {
          value: 2,
          label: "京东"
        },
        {
          value: 6,
          label: "阿里巴巴 "
        },
        {
          value: 7,
          label: "天猫"
        }
      ],
      formValue: 2,
      chooseList: [],
      chooseValue: "",
      choose: "",
      page: 1,
      more: true,
      loading: true,
      keyword: "",
      searchStatus: false
    };
  },
  methods: {
    kefu() {
      this.$store.commit("changekefu");
    },

    getType() {
      this.axios
        .get(this.api.pzleimu, {
          params: {
            source: this.formValue
          }
        })
        .then(res => {
          this.chooseList = res.data.data;
        });
    },
    load() {
      this.page = this.page + 1;
      this.getList();
    },
    search() {
      this.list = [];
      this.page = 1;
      this.getList();
    },
    //选择分类
    chooseType() {
      this.page = 1;
      this.list = [];
      this.getList();
    },
    //切换来源
    changeFormValue() {
      this.page = 1;
      this.list = [];
      this.chooseValue = "";
      this.getType();
      this.getList();
    },

    //清除搜索词
    clearSearch() {
      this.page = 1;
      this.list = [];
      this.getList();
    },

    // 获取产品列表
    getList() {
      // source:来源
      // category_id :类目id
      // search_words :搜索关键词
      this.loading = true;
      this.axios
        .get(this.api.getlist, {
          params: {
            source: this.formValue,
            category_id: this.chooseValue,
            page: this.page,
            search_words: this.keyword,
            shopid:this.$store.state.userinfo.shopid
          }
        })
        .then(res => {
          this.loading = false;
          this.list = [...this.list, ...res.data.list];
        });
    },
    // 铺货
    add(id){
      this.$store.commit('changeAdd',{id:id,type:0})
    },

    open(id,third_id) {
      this.$store.commit("changeDetail",{
        id,
        third_id,
        type:1
      });
     
    }
  },
  created() {
    this.getType();
     this.getList();
    
  },

  mounted() {
   
  },
  watch: {
    '$store.state.add.status':function(data){
      if(!data){
        this.list=[];
        this.page=1;
         this.getType();
         this.getList();
      }
    },
  }
};
</script>